<template>
  <div class="box-with-admin-data">
    <div class="columns-1">
      <div class="column-1">
        <div class="table-with-change-data">
          <table class="main-table" v-if="posts.length || posts == 1">
            <tr>
              <th>Denumirea deseului de metale feroase si neferoase</th>
              <th class="price-center">Pret pentru persoane juridice</th>
              <th class="price-center">Pret pentru persoane fizice</th>
            </tr>
            <tr
              v-for="(item, index) in posts"
              :key="index"
              class="row"
              @click="openModifier(item)"
            >
              <td>{{ item.title }}</td>
              <td class="price-center">{{ getTwoNumbers(item.pretJuridic, 'juridic') }}</td>
              <td class="price-center">{{ getTwoNumbers(item.pretJuridic, 'fizic') }}</td>
            </tr>
          </table>
          <div class="loading" v-else>
            <ProgressSpinner />
          </div>
          <div class="orase">
            <div class="grid">
              <div class="col">
                <p
                  class="city"
                  @click="getDataBasedOnCity('chisinau')"
                  :class="selectedRaion === 'chisinau' ? 'city-select' : ''"
                >
                  Chisinau
                </p>
              </div>
              <div class="col">
                <p
                  class="city"
                  @click="getDataBasedOnCity('balti')"
                  :class="selectedRaion === 'balti' ? 'city-select' : ''"
                >
                  Balti
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <changeValue
    v-if="showModifier"
    @closeModifier="closeModifier"
    :allSelectedData="allSelectedData"
  />
</template>

<script>
import changeValue from "./changeValue.vue";
import getMainTableData from "@/composables/getMainTableData";
import ProgressSpinner from "primevue/progressspinner";
import getMainTableDataBalti from "@/composables/getMainTableDataBalti";
export default {
  components: {
    changeValue,
    ProgressSpinner,
  },
  data() {
    return {
      posts: null,
      showModifier: false,
      allSelectedData: {},
      selectedRaion: "chisinau",
    };
  },
  created() {
    let data = this.getApiData();
    this.posts = data.metalPrice;
  },
  methods: {
    getTwoNumbers(number, tipPersoana){
      if(tipPersoana == 'fizic'){
        return (Number(number)  * 0.88).toFixed(2)
      }else{
        return Number(number).toFixed(2)
      }
    },
    getApiData() {
      const { metalPrice, error, load } = getMainTableData();

      load();

      return { metalPrice, error };
    },
    getApiDataBalti() {
      const { metalPrice, error, load } = getMainTableDataBalti();

      load();

      return { metalPrice, error };
    },
    openModifier(item) {
      this.showModifier = true;
      this.allSelectedData.id = item.id;
      this.allSelectedData.title = item.title;
      this.allSelectedData.pretJuridic = item.pretJuridic;
      this.allSelectedData.pretFizic = item.pretFizic;
      this.allSelectedData.oras = this.selectedRaion;
    },
    closeModifier(oras) {
      this.showModifier = false;
      this.posts = 1;
      this.getDataBasedOnCity(oras);
    },
    getDataBasedOnCity(city) {
      this.selectedRaion = city;
      let data = null;
      if (city === "chisinau") {
        data = this.getApiData();
      } else if (city === "balti") {
        data = this.getApiDataBalti();
      }
      this.posts = data.metalPrice;
    },
  },
};
</script>

<style scoped lang='scss'>
.loading {
  width: 100px;
  margin: 161px auto;
}
.box-with-admin-data {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 50px;
}
.columns-1 {
  display: flex;
}
.column-1 {
  flex: 1;
}
.table-with-change-data {
  width: 700px;
  height: 80  0px;
  background-color: #F5F7F7;
  margin: auto;
  border-radius: 40px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;
  min-height: 380px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  text-align: left;
  padding: 8px;
}
td {
  border-top: 1px solid #dddddd;
}
.row:hover {
  cursor: pointer;
  background-color: #dddddd;
}
.price-center {
  text-align: center;
}
.orase {
  width: 300px;
  margin: auto;
  text-align: center;
}
.city {
  border: 2px solid #cd9a30;
  padding: 4px 10px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
.city-select {
  background-color: #cd9a30;
}

@media (max-width: 710px) {
  .table-with-change-data {
    width: 500px;
    height: 1050px;
  }
  .loading {
    margin: 226px auto;
  }
}
@media (max-width: 515px) {
  .table-with-change-data {
    width: 300px;
    height: 1530px;
  }
  .loading {
    margin: 388px auto;
  }
  .orase{
      width: 200px
  }
}
</style>