<template>
  <div class="main-first-white-board">
    <h1 class="text-center color-orange">Valoarea metalelor pe bursa</h1>
    <div class="card">
      <div class="
          flex flex-wrap
          justify-content-center
          card-container
          blue-container
          column-gap-4
          row-gap-6
        " v-if="metalsmarket && lmeStock">
        <div class="
            w-17rem
            h-8rem
            align-items-center
            justify-content-center
            border-style
          ">
          <div class="columns">
            <div class="column">
              <p>
                <span class="bold">BID:</span>
                {{ metalsmarket.COPPER.BID }}
              </p>
              <p>
                <span class="bold">ASK:</span>
                {{ metalsmarket.COPPER.ASK }}
              </p>
            </div>
            <div class="column">
              <div class="col-right" style="float: right">
                <p class="style-metal-name bold">COPPER</p>
                <p class="up-or-down" :class="
                  lmeStock.COPPER.CHG.includes('-')
                    ? 'down-color'
                    : 'up-color'
                ">
                  <font-awesome-icon :icon="
                    lmeStock.COPPER.CHG.includes('-')
                      ? 'fa-solid fa-arrow-trend-down'
                      : 'fa-solid fa-arrow-trend-up'
                  " />&nbsp;
                  {{ removeMinusIfDown(lmeStock.COPPER.CHG) }}
                </p>
              </div>
            </div>
          </div>
          <p class="date-decor">Pentru data de: {{ lmeStock.DATE }}</p>
        </div>
        <div class="
            w-17rem
            h-8rem
            align-items-center
            justify-content-center
            border-style
          ">
          <div class="columns">
            <div class="column">
              <p>
                <span class="bold">BID:</span>
                {{ metalsmarket.ALUM.BID }}
              </p>
              <p>
                <span class="bold">ASK:</span>
                {{ metalsmarket.ALUM.ASK }}
              </p>
            </div>
            <div class="column">
              <div style="float: right">
                <p class="style-metal-name bold">ALUM</p>
                <p class="up-or-down" :class="
                  lmeStock.ALUM.CHG.includes('-') ? 'down-color' : 'up-color'
                ">
                  <font-awesome-icon :icon="
                    lmeStock.ALUM.CHG.includes('-')
                      ? 'fa-solid fa-arrow-trend-down'
                      : 'fa-solid fa-arrow-trend-up'
                  " />&nbsp;
                  {{ removeMinusIfDown(lmeStock.ALUM.CHG) }}
                </p>
              </div>
            </div>
          </div>
          <p class="date-decor">Pentru data de: {{ lmeStock.DATE }}</p>
        </div>
        <div class="
            w-17rem
            h-8rem
            align-items-center
            justify-content-center
            border-style
          ">
          <div class="columns">
            <div class="column">
              <p>
                <span class="bold">BID:</span>
                {{ metalsmarket.ZINC.BID }}
              </p>
              <p>
                <span class="bold">ASK:</span>
                {{ metalsmarket.ZINC.ASK }}
              </p>
            </div>
            <div class="column">
              <div style="float: right">
                <p class="style-metal-name bold">ZINC</p>
                <p class="up-or-down" :class="
                  lmeStock.ZINC.CHG.includes('-') ? 'down-color' : 'up-color'
                ">
                  <font-awesome-icon :icon="
                    lmeStock.ZINC.CHG.includes('-')
                      ? 'fa-solid fa-arrow-trend-down'
                      : 'fa-solid fa-arrow-trend-up'
                  " />&nbsp;
                  {{ removeMinusIfDown(lmeStock.ZINC.CHG) }}
                </p>
              </div>
            </div>
          </div>
          <p class="date-decor">Pentru data de: {{ lmeStock.DATE }}</p>
        </div>
        <div class="
            w-17rem
            h-8rem
            align-items-center
            justify-content-center
            border-style
          ">
          <div class="columns">
            <div class="column">
              <p>
                <span class="bold">BID:</span>
                {{ metalsmarket.NICKEL.BID }}
              </p>
              <p>
                <span class="bold">ASK:</span>
                {{ metalsmarket.NICKEL.ASK }}
              </p>
            </div>
            <div class="column">
              <div style="float: right">
                <p class="style-metal-name bold">NICKEL</p>
                <p class="up-or-down" :class="
                  lmeStock.NICKEL.CHG.includes('-')
                    ? 'down-color'
                    : 'up-color'
                ">
                  <font-awesome-icon :icon="
                    lmeStock.NICKEL.CHG.includes('-')
                      ? 'fa-solid fa-arrow-trend-down'
                      : 'fa-solid fa-arrow-trend-up'
                  " />&nbsp;
                  {{ removeMinusIfDown(lmeStock.NICKEL.CHG) }}
                </p>
              </div>
            </div>
          </div>
          <p class="date-decor">Pentru data de: {{ lmeStock.DATE }}</p>
        </div>
        <div class="
            w-17rem
            h-8rem
            align-items-center
            justify-content-center
            border-style
          ">
          <div class="columns">
            <div class="column">
              <p>
                <span class="bold">BID:</span>
                {{ metalsmarket.LEAD.BID }}
              </p>
              <p>
                <span class="bold">ASK:</span>
                {{ metalsmarket.LEAD.ASK }}
              </p>
            </div>
            <div class="column">
              <div style="float: right">
                <p class="style-metal-name bold">LEAD</p>
                <p class="up-or-down" :class="
                  lmeStock.LEAD.CHG.includes('-') ? 'down-color' : 'up-color'
                ">
                  <font-awesome-icon :icon="
                    lmeStock.LEAD.CHG.includes('-')
                      ? 'fa-solid fa-arrow-trend-down'
                      : 'fa-solid fa-arrow-trend-up'
                  " />&nbsp;
                  {{ removeMinusIfDown(lmeStock.LEAD.CHG) }}
                </p>
              </div>
            </div>
          </div>
          <p class="date-decor">Pentru data de: {{ lmeStock.DATE }}</p>
        </div>
        <div class="
            w-17rem
            h-8rem
            align-items-center
            justify-content-center
            border-style
          ">
          <div class="columns">
            <div class="column">
              <p>
                <span class="bold">BID:</span>
                {{ metalsmarket.TIN.BID }}
              </p>
              <p>
                <span class="bold">ASK:</span>
                {{ metalsmarket.TIN.ASK }}
              </p>
            </div>
            <div class="column">
              <div style="float: right">
                <p class="style-metal-name bold">TIN</p>
                <p class="up-or-down" :class="
                  lmeStock.TIN.CHG.includes('-') ? 'down-color' : 'up-color'
                ">
                  <font-awesome-icon :icon="
                    lmeStock.TIN.CHG.includes('-')
                      ? 'fa-solid fa-arrow-trend-down'
                      : 'fa-solid fa-arrow-trend-up'
                  " />&nbsp;
                  {{ removeMinusIfDown(lmeStock.TIN.CHG) }}
                </p>
              </div>
            </div>
          </div>
          <p class="date-decor">Pentru data de: {{ lmeStock.DATE }}</p>
        </div>
      </div>
      <div class="loading" v-else>
        <ProgressSpinner />
      </div>
    </div>
  </div>
</template>

<script>
import getMarketValue from "@/services/getMarketValue";
import ProgressSpinner from "primevue/progressspinner";
import errorHandle from "@/common/errorHandle";

export default {
  data() {
    return {
      lmeStock: null,
      metalsmarket: null,
    };
  },
  components: {
    ProgressSpinner,
  },
  created() {
    this.getMarketData();
    this.getLMEstocks();
  },
  methods: {
    async getMarketData() {
      try {
        const res = await getMarketValue.getMarket();
        this.metalsmarket = res.data[0];
      } catch (err) {
        errorHandle.apiError("getMarket", err)
      }
    },
    async getLMEstocks() {
      try {
        const res = await getMarketValue.getLME();
        this.lmeStock = res.data[0];
      } catch (err) {
        errorHandle.apiError("getLME", err)
      }
    },
    removeMinusIfDown(val) {
      if (val.includes("-")) {
        return val.substring(1);
      } else {
        return val;
      }
    },
  },
};
</script>

<style scoped>
.loading {
  width: 100px;
  margin: 70px auto;
}

.main-first-white-board {
  position: relative;
  margin-top: 100px;
  padding-bottom: 80px;
}

.card {
  max-width: 900px;
  margin: auto;
}

.border-style {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  background-color: #1E1E1A;
  color: white;
}

.col-left {
  margin-left: -50px;
}

.column {
  width: 120px;
}

.style-metal-name {
  color: #cd9a30;
  font-size: 18px;
  margin-top: 14px;
  text-align: end;
}

p {
  font-family: "Quantico", sans-serif;
  font-size: 15px;
}

.up-or-down {
  padding: 3px 10px 3px 10px;
  margin-top: -10px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
}

.up-color {
  background-color: #00be71;
}

.down-color {
  background-color: red;
}

.date-decor {
  margin: -1px 0 0 10px;
  color: rgb(189, 187, 187);
  font-size: 14px;
}

.columns {
  width: 250px;
  margin: auto;
}

.margin-right {
  margin-top: -5px;
}

@media (max-width: 1142px) {
  .main-first-white-board {
    margin-top: 400px;
  }
}
</style>