<template>
  <div
    class="main-contactData"
    @click.self="closeContact"
    tabindex="0"
    ref="modal"
    @keydown.esc="closeContact"
  >
    <div class="box-with-emails" :class="{'box-with-emails-active' : contacts.length > 0}" >
      <div class="no-contacts" v-if="contacts.length == 0">
        <h2 class="text-center">Nu aveti mesaje noi!</h2>
      </div>
      <div class="my-contacts">
        <div v-if="contacts.length > 0">
          <div
            class="single-contact"
            v-for="(contact, index) in contacts"
            :key="index"
          >
            <div class="columns">
              <div class="column">
                <div class="component-left">
                  <p><span class="bold">Nume:</span> {{ contact.name }}</p>
                  <p>
                    <span class="bold">Data:</span>
                    {{ getDate(contact.time.seconds) }}
                  </p>
                  <p><span class="bold">Email:</span> {{ contact.email }}</p>
                </div>
              </div>
              <div class="column">
                <div class="component-right">
                  <p>
                    <span class="bold">Comentariu: </span>{{ contact.comment }}
                  </p>
                </div>
              </div>
            </div>
            <Button
              label="Sterge"
              class="p-button-outlined p-button-danger button-delete"
              :key="compRerender"
              :loading="contact.loading"
              @click="deleteElement(contact, index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import { projectFirestore } from "@/firebase/config";
import errorHandle from "@/common/errorHandle";
import popUpHandle from "@/common/popUpHandle";
export default {
  components: {
    Button,
  },
  data() {
    return {
      compRerender: 0
    };
  },
  props: ["contacts"],
  mounted() {
    this.$refs.modal.focus();
  },
  created() {
    this.contacts.forEach(item => item.loading = false)
  },
  methods: {
    closeContact() {
      this.$emit("closeContact");
    },
    getDate(seconds) {
      const date = new Date(seconds * 1000);
      const isoString = date.toISOString();
      const normalDate =
        isoString.slice(8, 10) +
        "-" +
        isoString.slice(5, 7) +
        "-" +
        isoString.slice(0, 4) +
        " " +
        isoString.slice(11, 19);

      return normalDate;
    },
    async deleteElement(elem, index) {
      this.compRerender += 1;
      elem.loading = true
      try {
        const response = await projectFirestore
          .collection("contact")
          .doc(elem.id)
          .delete();
        if (index > -1 && response == undefined) {
          this.contacts.splice(index, 1);
          popUpHandle.showTopInfoModal("success", "Sters cu succes!", 3000)
        }
      } catch (err) {
        errorHandle.apiError("firebase delete contact", err)
      }
    },
  },
};
</script>

<style scoped>
.main-contactData {
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.3555);
  width: 100%;
  height: 100%;
  z-index: 99;
}
.box-with-emails {
  width: 800px;
  max-height: 500px;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
}
.box-with-emails-active {
  padding: 1rem;
}
.my-contacts {
  width: 95%;
  height: 400px;
  margin: auto;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-right: 0.5rem;
}
.single-contact {
  background-color: #f5f7f7;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.component-left {
  padding: 0 20px 20px 5px;
}
.component-right {
  padding: 0 10px 20px 0;
}
.button-delete {
  width: 100%;
}
.no-contacts{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}
@media (max-width: 810px) {
  .box-with-emails {
    width: 500px;
  }
  .columns {
    display: grid;
  }
  .component-right {
    padding: 0 10px 20px 5px;
    margin-top: -20px;
  }
}
@media (max-width: 510px) {
  .box-with-emails {
    width: 90%;
  }
  .button-delete {
    width: 200px;
  }
}
</style>