import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAuxz2BXUhOR1GgpXd3nEGX1uDcFiViuwY",
    authDomain: "metal-market-project.firebaseapp.com",
    projectId: "metal-market-project",
    storageBucket: "metal-market-project.appspot.com",
    messagingSenderId: "954939184461",
    appId: "1:954939184461:web:cb74ca512e2bee7e195304"
};

//init firebase
firebase.initializeApp(firebaseConfig)

//init firestore
const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, timestamp }