<template>
  <div class="main-ourservices">
    <div class="layer">
      <div class="data-insider">
        <h1 class="text-center color-orange">
          <span class="color-white">Serviciile</span> Noastre
        </h1>
        <div class="title-decor"></div>
        <div class="width-value">
          <div class="card">
            <div
              class="flex flex-wrap justify-content-center card-container gap-3"
            >
              <div
                class="
                  border-round
                  w-20rem
                  h-10rem
                  text-white
                  align-items-center
                  justify-content-center
                "
              >
                <div class="content-our-service icon-width">
                  <img src="../../assets/diffrent-icons/icon1.png" alt="icon" />
                </div>
                <h3 class="text-center margin-top-title">
                  <span class="color-orange">Monitorizare</span> online
                </h3>
              </div>
              <div
                class="
                  border-round
                  w-20rem
                  h-10rem
                  text-white
                  align-items-center
                  justify-content-center
                "
              >
                <div class="content-our-service icon-width">
                  <img
                    src="../../assets/diffrent-icons/recycling.png"
                    alt="icon"
                    class="icon-width"
                  />
                </div>
                <h3 class="text-center margin-top-title">
                  <span class="color-orange">Responsabilitate</span> Ecologică
                </h3>
              </div>
              <div
                class="
                  border-round
                  w-20rem
                  h-10rem
                  text-white
                  align-items-center
                  justify-content-center
                "
              >
                <div class="content-our-service icon-width">
                  <img
                    src="../../assets/diffrent-icons/modern-tech.png"
                    alt="icon"
                    class="icon-width"
                  />
                </div>
                <h3 class="text-center margin-top-title">
                  Infrastructură consolidată cu utilaje și tehnologii
                  <span class="color-orange">modernizate</span>
                </h3>
              </div>
              <div
                class="
                  border-round
                  w-20rem
                  h-10rem
                  text-white
                  align-items-center
                  justify-content-center
                "
              >
                <div class="content-our-service icon-width">
                  <img
                    src="../../assets/diffrent-icons/partner.png"
                    alt="icon"
                    class="icon-width"
                  />
                </div>
                <h3 class="text-center margin-top-title">
                  <span class="color-orange">Parteneriat</span> cu reciclatorii
                  finali, atât din țară cât și din străinătatea
                </h3>
              </div>
              <div
                class="
                  border-round
                  w-20rem
                  h-10rem
                  text-white
                  align-items-center
                  justify-content-center
                "
              >
                <div class="content-our-service icon-width">
                  <img
                    src="../../assets/diffrent-icons/message.png"
                    alt="icon"
                    class="icon-width"
                  />
                </div>
                <h3 class="text-center margin-top-title">
                  Mereu <span class="color-orange">alături</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.main-ourservices {
  /* The image used */
  background-image: url("../../assets/background-images/ourservices.jpg");

  /* Set a specific height */
  min-height: 650px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-clip-path: polygon(0 12%, 0 100%, 100% 87%, 100% 0);
  clip-path: polygon(0 12%, 0 100%, 100% 87%, 100% 0);

  .layer {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
  }
}
.icon-width {
  width: 96px;
  margin: auto;
}
.width-value {
  max-width: 1000px;
  margin: 60px auto;
}
.data-insider {
  margin-top: 100px;
}
.margin-top-title {
  margin-top: -10px;
}
.title-decor {
  max-width: 100px;
  margin: auto;
  border-bottom: 4px solid #cd9a30;
}
@media (max-width: 1000px) {
  .main-ourservices {
    min-height: 800px;
  }
}
@media (max-width: 665px) {
  .main-ourservices {
    min-height: 1250px;
  }
  .data-insider {
    margin-top: 150px;
  }
}
</style>