<template>
  <div class="main-field">
    <div class="layer">
      <div class="nav-bar-width">
        <div class="nav-bar">
          <a class="logo"
            ><router-link to="/" class="logo-router"
              ><img
                class="logo-img"
                src="../../assets/diffrent-icons/logo.png"
                alt="image" /></router-link
          ></a>
          <div class="navbar-links desktop-view">
            <ul>
              <li class="nav-top">
                <a
                  ><router-link class="link-router" to="/"
                    >Acasa</router-link
                  ></a
                >
              </li>
              <li class="nav-top">
                <a
                  ><router-link class="link-router" to="/desprecompanie"
                    >Despre Companie</router-link
                  ></a
                >
              </li>
              <li class="nav-top">
                <a
                  ><router-link class="link-router" to="/panoudemonitorizare"
                    >Panou de monitorizare</router-link
                  ></a
                >
              </li>
              <li>
                <a>
                  <Button
                    type="button"
                    class="p-button-outlined p-button-warning"
                    @click="contactUs"
                  >
                    <i class="pi pi-phone"></i>
                    <span class="ml-2 font-bold contact-margin-left"
                      >Contacteaza-ne</span
                    >
                  </Button></a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="despre-noi">
          <h1 class="despre-noi-dec color-orange">{{ this.panelName }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
export default {
  components: {
    Button,
  },
  props: ["panelName"],
  methods: {
    contactUs() {
      this.$router.push({ path: "/", name: "home", hash: "#footer" });
    },
  },
};
</script>

<style scoped>
.main-field {
  overflow: hidden;
  top: 0;
  background-image: url("../../assets/background-images/e.jpg");
  height: 270px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    0 100%,
    0 100%,
    100% 100%,
    100% 100%,
    0 100%,
    0 94%,
    100% 78%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    0 100%,
    0 100%,
    100% 100%,
    100% 100%,
    0 100%,
    0 94%,
    100% 78%,
    100% 0%
  );
  position: relative;
}
.layer {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}
.nav-bar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.navbar-links {
  margin-top: -50px;
}
.navbar-links ul {
  display: flex;
}
.nav-top {
  margin-right: 2rem;
  padding-top: 8px;
}
.navbar-links li {
  list-style: none;
}
ul li {
  cursor: pointer;
}
.link-router {
  color: white;
  text-decoration: none;
}
.link-router.router-link-active {
  background-color: #cd9a30;
  padding: 3px 12px;
  border-radius: 40px;
  font-weight: bold;
}
.despre-noi-dec {
  font-family: "Quantico", sans-serif;
  text-align: center;
  font-size: 50px;
  margin-top: -1px;
}
.contact-margin-left {
  margin-left: 8px;
  text-decoration: none;
}
.nav-bar-width {
  max-width: 1000px;
  margin: auto;
}
.logo-img {
  width: 170px;
}
@media (max-width: 1140px) {
  .nav-content-left {
    width: 650px;
    margin: 10px auto;
    text-align: center;
  }
  .nav-bar {
    padding: 0 20px 0 20px;
  }
  .address-and-tel {
    padding: 0 20px 0 20px;
  }
}
@media (max-width: 1050px) {
  .despre-noi-dec {
    margin-top: 20px;
  }
}
@media (max-width: 900px) {
  .address-and-tel {
    display: none;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .main-field {
    height: 350px;
  }
}
@media (max-width: 400px){
  .logo-img{
    width: 130px;
  }
}
</style>