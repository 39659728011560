<template>
  <NavBarForSmallComponents :panelName="'Panou de Monitorizare'" />
  <div class="mobile-view">
    <div
      class="menu-wrap"
      :class="isMenuOpen == true ? 'fixed' : ''"
      :key="keyComp"
    >
      <input
        type="checkbox"
        @click="isMenuOpen = !isMenuOpen"
        class="toggler"
      />
      <div class="hamburger"><div></div></div>
      <div class="menu">
        <div>
          <div class="center-nav-bar-mob">
            <ul>
              <li>
                <router-link to="/" @click="rerenderComp" class="acasa-link"
                  ><a>Acasa</a></router-link
                >
              </li>
              <li>
                <router-link
                  to="/desprecompanie"
                  @click="rerenderComp"
                  class="acasa-link"
                  ><a>Despre Companie</a></router-link
                >
              </li>
              <li>
                <router-link
                  to="/panoudemonitorizare"
                  @click="rerenderComp"
                  class="acasa-link"
                  ><a>Panou de monitorizare</a></router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tables">
    <div class="columns">
      <div class="column table1">
        <MainTable />
      </div>
      <div class="column table2">
        <div class="table2-in">
          <h1 class="text-center">Info</h1>
          <div class="text-table2">
            <p>
              <span class="star">*</span> Pretul unitar reprezentat in tabel
              este calculat pentru 1kg (Pret unitar/kg)
            </p>
            <p>
              <span class="star">*</span> Pretul pentru persoane fizice exclude
              impozitul pe venit de 12 %
            </p>
            <p>
              <span class="star">*</span> Preturile de procurare a deseurilor de
              metale feroase si neferoase reprezentate in tabel au intrat in
              viguare incepand cu <span class="color-orange">{{date}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="columns bottom-tables">
      <div class="column">
        <div class="table-left">
          <Table1 />
        </div>
      </div>
      <div class="column">
        <div class="table-center">
          <h1 class="text-center">Info</h1>
          <div class="text-table-center">
            <p>
              <span class="star">*</span> Valoarea stock este reprezentata in
              tone!
            </p>
            <p>
              <span class="star">*</span> Informatia reprezentata in tabele este
              pentru data de <span class="color-orange">"{{date}}"</span>
            </p>
            <p>
              <span class="star">*</span> Decontările în numerar la London Metal
              Exchange sunt reprezentate in USD (Dolar American)
            </p>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="table-right">
          <Table2 @date="setDate" />
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <Footer />
  </div>
</template>

<script>
import MainTable from "../components/HomeView/MainTable.vue";
import Table1 from "../components/Panel/Table1.vue";
import Table2 from "../components/Panel/Table2.vue";
import Footer from "../components/SmallReusableComponents/FooterSmall.vue";
import NavBarForSmallComponents from "../components/SmallReusableComponents/NavBarForSmallComponents.vue";
export default {
  components: {
    MainTable,
    Table1,
    Table2,
    Footer,
    NavBarForSmallComponents,
  },
  data() {
    return {
      tableDate: null,
      keyComp: 0,
      isMenuOpen: false,
      date: null
    };
  },
  methods: {
    rerenderComp() {
      this.keyComp += 1;
      this.isMenuOpen = false;
    },
    setDate(newDate){
      this.date = newDate
    }
  },
};
</script>

<style scoped>
.despre-noi-dec {
  text-shadow: 3px 3px 3px rgba(150, 150, 150, 0.79);
  text-align: center;
  font-size: 50px;
}
.tables {
  width: 1200px;
  margin: 30px auto;
}
.table1 {
  background-color: #1E1E1A;
  border-radius: 40px;
  height: 650px;
  width: 500px;
  padding-top: 10px;
}
.table2-in {
  background-color: #1E1E1A;
  border-radius: 40px;
  height: 650px;
  width: 500px;
  margin: 0 0 0 50px;
  padding-top: 130px;
}
.table2 {
  height: 380px;
  color: white;
}
.text-table2 {
  width: 400px;
  margin: auto;
}
.table-left {
  background-color: #1E1E1A;
  border-radius: 40px;
  height: 350px;
  width: 320px;
  margin-top: 30px;
  padding: 20px 0 0 10px;
}
.table-center {
  background-color: #1E1E1A;
  border-radius: 40px;
  height: 350px;
  width: 400px;
  margin-top: 30px;
  padding: 15px 0 0 10px;
  color: white;
}
.table-right {
  background-color: #1E1E1A;
  border-radius: 40px;
  height: 350px;
  width: 300px;
  margin: 30px 0 0 45px;
  padding: 50px 0 0 15px;
  color: white;
}
.text-table-center {
  width: 350px;
  margin: auto;
}
.mobile-view {
  display: none;
}
.center-nav-bar-mob {
  margin-left: -30px;
}
.acasa-link.router-link-active {
  color: #cd9a30;
}
.acasa-link:hover {
  text-decoration: underline;
}
@media (max-width: 1220px) {
  .columns {
    display: grid;
  }
  .tables {
    width: 600px;
  }
  .table1 {
    width: 600px;
  }
  .table2 {
    margin-top: 30px;
  }
  .bottom-tables {
    margin-top: 300px;
  }
  .table-left {
    width: 320px;
    margin: auto;
  }
  .table-center {
    width: 400px;
    margin: 30px auto;
  }
  .table-right {
    width: 300px;
    margin: auto;
  }
}
@media (max-width: 900px) {
  .mobile-view {
    display: block;
  }
}
@media (max-width: 660px) {
  .tables {
    width: 400px;
  }
  .table1 {
    height: 830px;
    width: 400px;
    margin: auto;
  }
  .table2-in {
    width: 400px;
    margin: auto;
  }
  .text-table2 {
    padding: 0 20px 0 20px;
  }
}
@media (max-width: 420px){
  .tables{
    width: 290px;
    margin: auto
  }
  .table1{
    width: 290px;
    height: 1050px;
    margin: auto
  }
  .table2-in {
    width: 290px;
    height: 640px;
    margin: auto;
  }
  .table-left{
    width: 290px;
    margin-top: 20px;
  }
  .table-center{
    width: 290px;
  }
  .table-right{
    width: 290px;
  }
  .text-table-center{
    width: 280px;
  }
  .text-table2{
    width: 280px;
  }
}
</style>