<template>
  <div class="main-footer">
    <div class="card">
      <div class="flex flex-wrap justify-content-center card-container gap-3">
        <div class="border-round w-30rem h-5rem text-white font-bold">
          <div class="columns icons-social-media">
            <div class="column">
              <a class="change-font-color" href="viber://chat?number=37360547171"><font-awesome-icon :icon="['fab', 'viber']" class="icon-fa" /></a>
            </div>
            <div class="column"><a href="https://t.me/+37360547171" target="_blank" class="change-font-color"><i class="pi pi-telegram"></i></a></div>
            <div class="column"><a href="https://api.whatsapp.com/send?phone=37360547171" target="_blank" class="change-font-color"><i class="pi pi-whatsapp"></i></a></div>
          </div>
        </div>
        <div
          class="
            border-round
            w-30rem
            h-5rem
            text-white
            font-bold
            text-footer-right
          "
        >
          <p class="copy">
            &copy; Copyright
            <span class="color-orange">ARVI INVEST TRADING</span>. Toate
            drepturile sunt rezervate de catre autor.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main-footer {
  margin-top: 10px;
  height: 140px;
  background-color: #1E1E1A;
  -webkit-clip-path: polygon(0 32%, 0% 100%, 100% 100%, 100% 6%);
  clip-path: polygon(0 32%, 0% 100%, 100% 100%, 100% 6%);
}
.columns {
  width: 200px;
  margin-top: 70px;
}
.text-footer-right {
  margin-top: 45px;
}
.column i {
  font-size: 1.8rem;
}
.column i:hover {
  cursor: pointer;
  color: #cd9a30;
}
.icon-fa {
  font-size: 30px;
}
.icon-fa:hover {
  cursor: pointer;
  color: #cd9a30;
}
.change-font-color{
  color: white
}
@media (max-width: 985px) {
  .main-footer {
    height: 250px;
    -webkit-clip-path: polygon(0 15%, 0% 100%, 100% 100%, 100% 0%);
    clip-path: polygon(0 15%, 0% 100%, 100% 100%, 100% 0%);
  }
  .icons-social-media{
    width: 200px;
    margin: 80px auto;
    padding-left: 30px;
  }
  .copy{
    text-align: center;
  }
}
</style>