<template>
  <div class="table-box">
    <table class="main-table" v-if="mainTable != null">
      <tr>
        <th class="text-center">METAL</th>
        <th class="text-center">STOCK</th>
        <th class="text-center">CHG%</th>
      </tr>
      <tr v-for="(item, index) in mainTable" :key="index">
        <td v-if="index != 'DATE'">{{ index }}</td>
        <td class="text-center second-row">
          <div class="decor-td" v-if="index != 'DATE'">{{ item.STOCK }}</div>
        </td>
        <td class="text-center">
          <div class="decor-td" v-if="index != 'DATE'" :class="item.CHG.includes('-') ? 'down' : 'up'">{{ item.CHG }}</div>
        </td>
      </tr>
    </table>
    <div class="spinner-div" v-else>
      <ProgressSpinner class="spinner" />
    </div>
  </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";
import axios from "axios";
import errorHandle from "@/common/errorHandle";
import getMarketValue from "@/services/getMarketValue";

export default {
  components: {
    ProgressSpinner,
  },
  data() {
    return {
      mainTable: null,
      loading: false,
    };
  },
  created() {
    this.getTableData()
  },
  methods: {
    async getTableData() {
      this.loading = true;
      try {
        const res = await getMarketValue.getLME()
        this.mainTable = res.data[0];
        this.$emit('date', this.mainTable.DATE)
      }catch(err) {
        errorHandle.apiError('getLME', err)
      }finally{
        this.loading = false
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-box {
  padding: 5px 20px;
  color: white;
  position: absolute;
  width: 260px;

  overflow: hidden;
  th,
  td {
    margin: 0.5em 1em;
  }
  th,
  td:before {
    color: white;
  }
  th {
    font-size: 18px;
    color: #cd9a30;
    padding-bottom: 10px;
  }
  th:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    //padding-left: 15px;
  }
  th:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    //padding-right: 15px;
  }
  td:first-child {
    font-weight: bold;
    font-size: 14px;
  }
}
table {
  border-collapse: collapse;
  border-style: hidden; /* hide standard table (collapsed) border */
}
.second-row{
  padding-left: 20px;
}
.decor-td {
  min-width: 50px;
  background-color: rgba(205, 154, 48, 0.7);
  border-radius: 10px;
  padding: 3px 10px;
  color: white;
  font-weight: bold;
}
td {
  color: white;
}
.spinner-div {
  width: 100px;
  margin: 60px auto;
}
.up {
  font-size: 18px;
  min-width: 50px;
  background-color: #00be71;
  border-radius: 10px;
  padding: 3px 10px;
  color: white;
  font-weight: bold;
  margin-left: 3px
}
.down {
  font-size: 18px;
  min-width: 50px;
  background-color: red;
  border-radius: 10px;
  padding: 3px 10px;
  color: white;
  font-weight: bold;
  margin-left: 3px
}
@media (max-width: 660px) {
  .table-box {
    width: 290px;
    padding: 0 10px;
  }
}
</style>