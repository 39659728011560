<template>
  <div class="home">
    <div class="home-header">
      <NavBar />
      <div class="mobile-view">
        <div
          class="menu-wrap"
          :class="isMenuOpen == true ? 'fixed' : ''"
          :key="keyComp"
        >
          <input type="checkbox" @click="isMenuOpen = !isMenuOpen" class="toggler" />
          <div class="hamburger"><div></div></div>
          <div class="menu">
            <div>
              <div class="center-nav-bar-mob">
                <ul>
                  <li><router-link to="/" @click="rerenderComp" class="acasa-link"><a>Acasa</a></router-link></li>
                  <li><router-link to="/desprecompanie" @click="rerenderComp" class="acasa-link"><a>Despre Companie</a></router-link></li>
                  <li><router-link to="/panoudemonitorizare" @click="rerenderComp" class="acasa-link"><a>Panou de monitorizare</a></router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-on-top">
        <div class="box">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div class="content">
            <MainTable />
          </div>
        </div>
      </div>
    </div>
    <div class="home-body">
      <FirstWhiteBord />
      <CaruselComponent />
      <Descriere :showTitle="'show'"/>
      <Footer id="footer" />
    </div>
  </div>
</template>

<script>
import NavBar from "../components/HomeView/NavBar.vue";
import MainTable from "../components/HomeView/MainTable.vue";
import FirstWhiteBord from "../components/HomeView/FirstWhiteBord.vue";
import CaruselComponent from "../components/HomeView/CaruselComponent.vue";
import Descriere from "../components/HomeView/Descriere.vue";
import Footer from "../components/HomeView/Footer.vue";
import "../assets/styles/navBar.scss";

export default {
  components: {
    NavBar,
    MainTable,
    FirstWhiteBord,
    CaruselComponent,
    Descriere,
    Footer,
  },
  data() {
    return {
      isMenuOpen: false,
      keyComp: 0
    };
  },
  methods: {
    rerenderComp(){
      this.keyComp += 1
      this.isMenuOpen = false
    }
  }
};
</script>

<style scoped lang="scss">
.mobile-view {
  display: none;
}
.box-on-top {
  width: 1000px;
  position: relative;
  margin: auto;
}
@mixin step4($width, $color, $bgcolor) {
  box-shadow: $width 0 $bgcolor, -$width 0 $bgcolor, 0 (-$width) $bgcolor,
    0 $width $bgcolor, 2 * $width 0 $bgcolor, -2 * $width 0 $bgcolor,
    0 (-2 * $width) $bgcolor, 0 2 * $width $bgcolor, 0 0 0 $width $bgcolor,
    3 * $width 0 $bgcolor, -3 * $width 0 $bgcolor, 0 3 * $width $bgcolor,
    0 (-3 * $width) $bgcolor, 0 (-$width) 0 $width $bgcolor,
    0 $width 0 $width $bgcolor, $width 0 0 $width $bgcolor,
    -$width 0 0 $width $bgcolor, 4 * $width 0 $color, -4 * $width 0 $color,
    0 (-4 * $width) $color, 0 4 * $width $color, 2 * $width 0 0 $width $color,
    -2 * $width 0 0 $width $color, 0 2 * $width 0 $width $color,
    0 (-2 * $width) 0 $width $color, 0 0 0 2 * $width $color;
  margin: 4 * $width auto;
}
.lvl2 {
  @include step4(3.5px, #cd9a30, rgba(255, 255, 255, 0.5));
}
.box {
  position: absolute;
  top: -250px;
  right: -350px;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 650px;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 20px 50px rgba(205, 154, 48, 0.7);
  border: 2px solid #cd9a30;
  color: white;
  padding: 20px 10px;
}

.box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s;
  pointer-events: none;
}
.box:hover:before {
  left: -50%;
  transform: skewX(-5deg);
}
.box span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 596px;
  display: block;
  box-sizing: border-box;
}
.box span:nth-child(1) {
  transform: rotate(0deg);
}
.box span:nth-child(2) {
  transform: rotate(90deg);
}
.box span:nth-child(3) {
  transform: rotate(180deg);
  height: 650px;
}
.box span:nth-child(4) {
  transform: rotate(270deg);
}
.acasa-link.router-link-active{
  color: #cd9a30;
}
.box span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #cd9a30;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}
.acasa-link:hover {
  text-decoration: underline;
}
.center-nav-bar-mob{
  margin-left: -30px;
}
.fixed {
  position: fixed;
}
@media (max-width: 1140px) {
  .box-on-top {
    width: 500px;
    margin-top: 250px;
  }
  .box {
    top: -205px;
  }
}
@media (max-width: 900px) {
  .box-on-top {
    margin-top: 180px;
  }
  .mobile-view {
    display: block;
  }
}
@media (max-width: 660px) {
  .box-on-top {
    width: 50px;
    margin-top: 230px;
  }
  .main-first-white-board{
    margin-top: 550px;
  }
  .box {
    width: 400px;
    right: -375px;
    height: 830px;
    top: -160px;
  }
  .box span:nth-child(3) {
    height: 830px;
  }
}
@media (max-width: 650px){
  .box-on-top{
    margin-top: 270px;
  }
  .main-first-white-board{
    margin-top: 600px;
  }
}
@media (max-width: 585px) {
  .box-on-top {
    margin-block: 300px;
  }
  .main-first-white-board{
    margin-top: 680px;
  }
}
@media (max-width: 504px) {
  .box-on-top {
    margin-block: 320px;
  }
}
@media (max-width: 420px) {
  .box-on-top {
    width: 200px;
    margin-top: 470px;
  }
  .box {
    width: 300px;
    right: -200px;
    height: 1050px;
    top: -205px;
  }
  .main-first-white-board{
    margin-top: 830px;
  }
  .box span:nth-child(3) {
    height: 1050px;
  }
}
@media (max-width: 365px){
  .box{
    top: -200px;
  }
  .main-first-white-board{
    padding-top: 10px;
  }
}
@media (max-width: 350px){
  .box{
    top: -150px;
  }
  .main-first-white-board{
    padding-top: 50px;
  }
}
@media (max-width: 300px){
  .box{
    top: -140px;
  }
  .main-first-white-board{
    padding-top: 50px;
  }
}
@media (max-width: 260px){
  .box{
    top: -200px;
  }
  .main-first-white-board{
    padding-top: 50px;
  }
}
</style>
