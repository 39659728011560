import errorHandle from '@/common/errorHandle'
import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getContactInfo = () => {

    const contact = ref([])
    const error = ref(null)

    const load = async () => {
        try {
            const res = await projectFirestore.collection('contact').orderBy('time', 'desc').get()
            contact.value = res.docs.map(doc => {
                return { ...doc.data(), id: doc.id }
            })
        }
        catch (err) {
            error.value = err.message
            errorHandle.apiError("firebase get contact", err.message)
        }
    }

    return { contact, error, load }
}

export default getContactInfo