import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutUs from '../views/AboutUs.vue'
import PanouDeMonitorizare from '../views/PanouDeMonitorizare.vue'
import { projectAuth } from '@/firebase/config'
import LoginForm from '../components/Admin/LoginForm.vue'
import Admin from '../components/Admin/AdminPanel.vue'

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if(!user){
    next({ name: 'LoginForm'})
  }else{
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/desprecompanie',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/panoudemonitorizare',
    name: 'PanouDeMonitorizare',
    component: PanouDeMonitorizare
  },
  {
    path: '/login',
    name: 'LoginForm',
    component: LoginForm
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: requireAuth
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      document.getElementById("footer").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    } else {
      return { x: 0, y: 0 };
    }
  }
})

export default router
