<template>
  <div class="main-oursocialmedia">
    <h1 class="text-center color-orange">La un Click Distanţă</h1>
    <p class="text-center decor-font">
      Stiri, conversatii, oferte - selecteaza platforma preferata pentru a fi
      mereu la curent cu ce se intampla pe piata metalelor!
    </p>
    <div class="card">
      <div
        class="
          flex flex-wrap
          justify-content-center
          card-container
          blue-container
          gap-4
        "
      >
        <div
          class="
            border-round
            w-13rem
            h-14rem
            align-items-center
            justify-content-center
          "
        >
          <a href="https://t.me/+37360547171" class="color-black remove-a-decor" target="_blank">
            <div class="box-around">
              <div class="icon-contact">
                <img src="../../assets/social-media/telegram.png" alt="image-telegram" />
              </div>
              <h2 class="text-center remove-margin-top">Telegram</h2>
              <p class="text-center">+373 60 54 71 71</p>
            </div>
          </a>
        </div>
        <div
          class="
            border-round
            w-13rem
            h-14rem
            align-items-center
            justify-content-center
          "
        >
        <a href="viber://chat?number=37360547171" class="color-black remove-a-decor">
          <div class="box-around">
            <div class="icon-contact">
              <img src="../../assets/social-media/viber.png" alt="image-viber" />
            </div>
            <h2 class="text-center remove-margin-top">Viber</h2>
            <p class="text-center">+373 60 54 71 71</p>
          </div>
        </a>
        </div>
        <div
          class="
            border-round
            w-13rem
            h-14rem
            align-items-center
            justify-content-center
          "
        >
        <a href="https://api.whatsapp.com/send?phone=37360547171" class="color-black remove-a-decor">
          <div class="box-around">
            <div class="icon-contact">
              <img src="../../assets/social-media/whatsupp.png" alt="image-whatsupp" />
            </div>
            <h2 class="text-center remove-margin-top">WhatsApp</h2>
            <p class="text-center">+373 60 54 71 71</p>
          </div>
        </a>
        </div>
        <div
          class="
            border-round
            w-13rem
            h-14rem
            align-items-center
            justify-content-center
          "
        >
          <div class="box-around" @click="openSocial('facebook')">
            <div class="icon-contact">
              <img src="../../assets/social-media/facebook.png" alt="image-facebook" />
            </div>
            <h2 class="text-center remove-margin-top">FaceBook</h2>
            <p class="text-center">@arviinvesttrading</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openSocial(social) {
      const facebook =
        "https://www.facebook.com/Arvi-Invest-Trading-112404534547279";
      let URL = null;
      if (social === "facebook") {
        URL = facebook;
      }
      window.open(URL, "_blank");
    },
  },
};
</script>

<style scoped>
.main-oursocialmedia {
  min-height: 440px;
}
.card {
  padding-top: 30px;
}
.icon-contact {
  width: 128px;
  margin: auto;
}
.remove-margin-top {
  margin-top: -5px;
}
.box-around {
  background: #e7ebea;
  padding: 20px 20px 5px 20px;
  border-radius: 30px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.box-around:hover {
  cursor: pointer;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.decor-font {
  font-size: 18px;
  width: 500px;
  margin: auto;
}
@media (max-width: 915px) {
  .main-oursocialmedia {
    height: 650px;
  }
}
@media (max-width: 530px) {
  .decor-font {
    width: 300px;
  }
  .main-oursocialmedia {
    height: 690px;
  }
}
@media (max-width: 450px) {
  .main-oursocialmedia {
    height: 1190px;
  }
}
</style>