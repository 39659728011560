<template>
  <div class="parallax">
    <div class="layer">
      <div class="card">
        <Carousel
          :value="cars"
          :numVisible="3"
          :numScroll="1"
          :responsiveOptions="responsiveOptions"
          class="custom-carousel"
          :circular="true"
          :autoplayInterval="3000"
        >
          <template #header>
            <h1 class="text-center color-orange">Produse</h1>
          </template>
          <template #item="cars">
            <div class="each-element">
              <div class="image">
                <!-- <img src="../../assets/inox.avif" class="img" alt="image" /> -->
                <img
                  :src="getImageUrl(cars.data.image)"
                  class="img"
                  alt="image"
                />
              </div>
              <h2>{{ cars.data.text }}</h2>
            </div>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "primevue/carousel";

export default {
  components: {
    Carousel,
  },
  data() {
    return {
      products: null,
      cars: [
        {
          image: "metals-carousel/scrap-copper.jpg",
          text: "Deseuri de cupru",
        },
        {
          image: "metals-carousel/scrap-alum.jpg",
          text: "Deseuri de aluminiu",
        },
        {
          image: "metals-carousel/scrap-bronze.jpg",
          text: "Deseuri de bronz",
        },
        {
          image: "metals-carousel/scrap-inox.jpg",
          text: "Deseuri de inox",
        },
        {
          image: "metals-carousel/scrap-metal.jpg",
          text: "Deseuri de fier",
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  methods: {
    getImageUrl(image) {
      return require("../../assets/" + image);
    },
  },
};
</script>

<style scoped lang="scss">
.parallax {
  /* The image used */
  background-image: url("../../assets/background-images/working-man.jpg");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-clip-path: polygon(0 12%, 0 100%, 100% 87%, 100% 0);
  clip-path: polygon(0 12%, 0 100%, 100% 87%, 100% 0);

  .layer {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
  }
}
.product-item {
  .product-item-content {
    border: 1px solid var(--surface-border);
    border-radius: 3px;
    margin: 0.3rem;
    text-align: center;
    padding: 2rem 0;
  }

  .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
.card {
  max-width: 1000px;
  margin: auto;
  margin-top: 35px;
}
.each-element {
  text-align: center;
}
.image {
  margin: auto;
  width: 290px;
}
.img {
  width: 290px;
  height: 200px;
  border-radius: 30px;
}
@media (max-width: 990px) {
  .image {
    margin: auto;
    width: 200px;
  }
  .img {
    width: 200px;
    height: 150px;
  }
}
@media (max-width: 725px) {
  .image {
    margin: auto;
    width: 150px;
  }
  .img {
    width: 150px;
    height: 100px;
  }
  .card{
    margin-top: 70px;
  }
}
</style>