import { createApp } from "vue";
import PrimeVue from 'primevue/config';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faViber, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue';
import router from './router'
import { projectAuth } from './firebase/config';

// styles
import './assets/styles/main.scss'
import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import '/node_modules/primeflex/primeflex.css'

library.add(fas, faViber, faInstagram, faFacebookF)

let app;

projectAuth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App)
        app.component('font-awesome-icon', FontAwesomeIcon)
        app.use(router)
        app.use(PrimeVue)
        app.mount('#app')
    }
})

