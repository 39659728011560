<template>
  <NavBarForSmallComponents :panelName="'Admin Panel'" />
  <div class="mobile-view">
    <div
      class="menu-wrap"
      :class="isMenuOpen == true ? 'fixed' : ''"
      :key="keyComp"
    >
      <input
        type="checkbox"
        @click="isMenuOpen = !isMenuOpen"
        class="toggler"
      />
      <div class="hamburger"><div></div></div>
      <div class="menu">
        <div>
          <div class="center-nav-bar-mob">
            <ul>
              <li>
                <router-link to="/" @click="rerenderComp" class="acasa-link"
                  ><a>Acasa</a></router-link
                >
              </li>
              <li>
                <router-link
                  to="/desprecompanie"
                  @click="rerenderComp"
                  class="acasa-link"
                  ><a>Despre Companie</a></router-link
                >
              </li>
              <li>
                <router-link
                  to="/panoudemonitorizare"
                  @click="rerenderComp"
                  class="acasa-link"
                  ><a>Panou de monitorizare</a></router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-adminpanel">
    <div class="columns">
      <div class="column">
        <MainTable />
      </div>
      <div class="column">
        <div class="element-outside-contact" @click="showContact = true">
          <Badge
            class="decor-badge"
            size="large"
            :value="contacts.length"
          ></Badge>
          <div class="icon-decor">
            <i class="pi pi-comments color-orange icon-dec"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Contact
    :contacts="contacts"
    v-if="showContact"
    @closeContact="showContact = false"
  />
</template>

<script>
import NavBarForSmallComponents from "../SmallReusableComponents/NavBarForSmallComponents.vue";
import MainTable from "./SmallAdminComponents/MainTable.vue";
import Badge from "primevue/badge";
import getContactInfo from "@/composables/getContactInfo.js";
import Contact from "./SmallAdminComponents/Contact.vue";

export default {
  data() {
    return {
      keyComp: 0,
      isMenuOpen: false,
      contacts: null,
      showContact: false,
    };
  },
  components: {
    NavBarForSmallComponents,
    MainTable,
    Badge,
    Contact,
  },
  created() {
    let data = this.getApiData();
    this.contacts = data.contact;
  },
  methods: {
    rerenderComp() {
      this.keyComp += 1;
      this.isMenuOpen = false;
    },
    getApiData() {
      const { contact, error, load } = getContactInfo();

      load();

      return { contact, error };
    },
  },
};
</script>

<style scoped lang="scss">
.mobile-view {
  display: none;
}
.center-nav-bar-mob {
  margin-left: -30px;
}
.acasa-link.router-link-active {
  color: #cd9a30;
}
.acasa-link:hover {
  text-decoration: underline;
}
.main-adminpanel {
  max-width: 1200px;
  margin: auto;
}
.element-outside-contact {
  width: 400px;
  height: 525px;
  background-color: #e7ebea67;
  border-radius: 50px;
  margin: 10px 0 0 40px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.icon-dec {
  font-size: 200px;
}
.element-outside-contact:hover {
  cursor: pointer;
  .icon-dec {
    font-size: 205px;
  }
  .icon-decor {
    width: 205px;
    margin: auto;
    padding-top: 148px;
  }
}
.decor-badge {
  float: right;
  background-color: #cd9a30;
}
.icon-decor {
  width: 200px;
  margin: auto;
  padding-top: 150px;
}
@media (max-width: 1220px) {
  .columns {
    display: grid;
  }
  .element-outside-contact {
    width: 700px;
    height: 300px;
    margin: auto;
    margin-bottom: 40px;
  }
  .icon-decor {
    padding-top: 50px;
  }
  .element-outside-contact:hover {
    .icon-decor {
      padding-top: 48px;
    }
  }
}
@media (max-width: 900px) {
  .mobile-view {
    display: block;
  }
}
@media (max-width: 710px){
  .element-outside-contact {
    width: 500px;
  }
}
@media (max-width: 515px){
  .element-outside-contact {
    width: 300px;
  }
}
</style>