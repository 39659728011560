<template>
  <div class="main-data">
    <div class="navbar">
      <div class="layer">
        <div class="all-content">
          <div class="address-and-tel">
            <p>
              <span class="color-orange bold"
                ><i class="pi pi-map-marker"></i> Adresa:
              </span>
              <a
                class="remove-a-decor color-white"
                target="_blank"
                href="https://goo.gl/maps/UEeorpWKsgsPgJqZ6"
                >or.Chișinău, str. Lunca Bîcului 35</a
              >
            </p>
            <div class="columns">
              <p class="margin-right-tel-from-email">
                <span class="color-orange bold"
                  ><i class="pi pi-phone"></i> Telefon:
                </span>
                <a class="remove-a-decor color-white" href="tel: +37360547171"
                  >060 54 71 71</a
                >
              </p>
              <p>
                <span class="color-orange bold"
                  ><i class="pi pi-envelope"></i> Email:
                </span>
                <a
                  class="remove-a-decor color-white"
                  href="mailto: office@arvi.md"
                  >office@arvi.md</a
                >
              </p>
            </div>
          </div>
          <div class="nav-bar">
            <a class="logo"
              ><router-link to="/" class="logo-router"
                ><img
                  class="logo-img"
                  src="../../assets/diffrent-icons/logo.png"
                  alt="image" /></router-link
            ></a>
            <div class="navbar-links desktop-view">
              <ul>
                <li class="nav-top">
                  <a
                    ><router-link class="link-router" to="/"
                      >Acasa</router-link
                    ></a
                  >
                </li>
                <li class="nav-top">
                  <a
                    ><router-link class="link-router" to="/desprecompanie"
                      >Despre Companie</router-link
                    ></a
                  >
                </li>
                <li class="nav-top">
                  <a
                    ><router-link class="link-router" to="/panoudemonitorizare"
                      >Panou de monitorizare</router-link
                    ></a
                  >
                </li>
                <li>
                  <a
                    >
                      <Button
                        type="button"
                        class="p-button-outlined p-button-warning"
                        @click="nav"
                      >
                        <i class="pi pi-phone"></i>
                        <span class="ml-2 font-bold contact-margin-left"
                          >Contacteaza-ne</span
                        >
                      </Button></a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-content">
            <div class="nav-content-left">
              <h1 class="color-orange">
                Împreună pentru un viitor verde și ecologic.
              </h1>
              <p>
                Prin menținerea și aplicarea eficace a sistemului de management al
                calității în conformitate cu cerințele standardului respectiv,
                compania urmărește să asigure credibilitatea, să consolideze
                încrederea și să crească satisfacția clienților săi.
              </p>
              <div class="button-see-more">
                <router-link class="remove-a-decor" to="/desprecompanie"><Button type="button" class="p-button-outlined p-button-warning">
                  <span class="ml-2 font-bold">Vezi mai mult</span>
                  <i class="pi pi-arrow-right contact-margin-left"></i>
                </Button></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import "../../assets/styles/navBar.scss";

export default {
  components: {
    Button
  },
  methods: {
    nav() {
      const position = document.getElementById('footer').offsetTop;
      // smooth scroll
      window.scrollTo({ top: position + 120, behavior: "smooth" });
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Quantico&display=swap');
.mobile-view {
  display: none;
}
.navbar {
  background-image: url("../../assets/background-images/e.jpg");
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    0 100%,
    0 100%,
    100% 100%,
    100% 100%,
    0 100%,
    0 94%,
    100% 78%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    0 100%,
    0 100%,
    100% 100%,
    100% 100%,
    0 100%,
    0 94%,
    100% 78%,
    100% 0%
  );
}
.layer {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}
.address-and-tel {
  display: flex;
  justify-content: space-between;
}
.all-content {
  max-width: 1100px;
  margin: auto;
}
.nav-bar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.margin-right-tel-from-email {
  margin-right: 20px;
}
.navbar-links {
  margin-top: -50px;
}
.navbar-links ul {
  display: flex;
}
.nav-top {
  margin-right: 2rem;
  padding-top: 8px;
}
.navbar-links li {
  list-style: none;
}
ul li {
  cursor: pointer;
}
.link-router {
  color: white;
  text-decoration: none;
}
.link-router.router-link-active{
  background-color: #cd9a30;
  padding: 3px 12px;
  border-radius: 40px;
  font-weight: bold;
}
.contact-margin-left {
  margin-left: 8px;
}
.nav-content {
  display: flex;
  position: relative;
  justify-content: space-between;
}
.nav-content-left {
  margin-top: 30px;
  width: 350px;
}
@media (max-width: 1140px) {
  .nav-content-left {
    width: 650px;
    margin: 10px auto;
    text-align: center;
  }
  .nav-bar {
    padding: 0 20px 0 20px;
  }
  .address-and-tel {
    padding: 0 20px 0 20px;
  }
}
@media (max-width: 900px) {
  .address-and-tel {
    display: none;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
}
.logo-img {
  width: 170px;
}
@media (max-width: 400px){
  .logo-img{
    width: 130px;
  }
}
</style>