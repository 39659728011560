import Swal from "sweetalert2"

export default {
    showTopInfoModal(icon, title, timer) {
        Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: timer,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
            icon: icon,
            title: title,
          });
    }
}