<template>
  <div class="main-footer">
    <div class="layer">
      <div class="card">
        <h1 class="text-center color-orange">Contacteaza-ne</h1>
        <div class="flex flex-wrap justify-content-center gap-3">
          <div class="
              border-round
              w-30rem
              h-40rem
              text-white
              font-bold
              left-footer-box
            ">
            <p>
              <i class="pi pi-map-marker"></i><span class="color-orange bold"> Adresa: </span>
              <a class="remove-a-decor color-white" target="_blank"
                href="https://goo.gl/maps/UEeorpWKsgsPgJqZ6">or.Chișinău, str. Lunca Bîcului 35</a>
            </p>
            <p class="margin-right-tel-from-email">
              <i class="pi pi-phone"></i><span class="color-orange bold"> Telefon: </span>
              <a class="remove-a-decor color-white" href="tel: +37360547171">060 54 71 71</a>
            </p>
            <p>
              <i class="pi pi-envelope"></i>
              <span class="color-orange bold"> Email: </span>
              <a class="remove-a-decor color-white" href="mailto: office@arvi.md">office@arvi.md</a>
            </p>
            <hr />
            <p>
              <i class="pi pi-clock"></i>
              <span class="color-orange bold"> Orele de Lucru: </span>
            </p>
            <p>(Luni-Vineri) 8:00 - 18:00</p>
            <p>(Sambata) 8:00 - 13:00</p>
            <p>Duminica zi de odihna</p>
            <div class="iframe-div">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2721.4612275490426!2d28.900838234887694!3d46.991915999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97b5bb21c6403%3A0x21a3db4edc609276!2sArvi%20Invest%20Trading%20SRL%20Lunca%20Bicului%2035!5e0!3m2!1sen!2s!4v1656418269834!5m2!1sen!2s"
                style="border: 0" allowfullscreen="" loading="lazy" class="iframe"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="logo-footer">
              <img class="logo-img" src="../../assets/diffrent-icons/logo.png" alt="image" />
            </div>
          </div>
          <div class="border-round w-30rem h-40rem text-white font-bold">
            <div class="elements-inside">
              <div class="write-to-me">
                <p>
                  Suntem la un click distanta. Scrie-ne un mesaj utilizand forma
                  de mai jos, noi vom reveni cu un raspuns in cel mai scurt timp
                  la adresa electronica indicata.
                </p>
              </div>
              <Form class="input-filed" />
              <div class="copyright">
                <p class="text-center">
                  &copy; Copyright ARVI INVEST TRADING. Toate drepturile sunt
                  rezervate de catre autor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./components/Form.vue"

export default {
  components: {
    Form
  }
};
</script>

<style scoped lang='scss'>
textarea {
  resize: none;
}

.main-footer {
  min-height: 1000px;
  background: url("../../assets/background-images/footer.jpg");
  -webkit-clip-path: polygon(0 14%, 0% 100%, 100% 100%, 100% 0%);
  clip-path: polygon(0 14%, 0% 100%, 100% 100%, 100% 0%);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}

.layer {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}

.card {
  padding-top: 130px;
}

.logo-footer {
  margin-top: 50px;
}

.logo-img {
  width: 150px;
}

.sizes {
  .p-inputtext {
    display: block;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.field * {
  display: block;
}

.elements-inside {
  width: 400px;
  margin: auto;
}

.width-input {
  width: 400px;
}

.for-btn {
  width: 300px;
  margin-top: 20px;
}

.btn {
  width: 400px;
}

.copyright {
  margin-top: 80px;
}

.write-to-me p {
  text-align: center;
}

.popUpError {
  color: yellow;
}

.input-filed {
  height: 470px;
}

.iframe {
  width: 480px;
  height: 300px;
}

@media (max-width: 1090px) {
  .left-footer-box {
    padding-left: 20px;
  }
}

@media (max-width: 1000px) {
  .main-footer {
    min-height: 1050px;
  }

  .elements-inside {
    margin-top: 50px;
  }

  .logo-footer {
    width: 150px;
    margin: auto;
    margin-top: 50px;
  }

  .card {
    padding-top: 160px;
  }
}

@media (max-width: 985px) {
  .card {
    padding-top: 100px;
  }

  .main-footer {
    height: 1750px;
    -webkit-clip-path: polygon(0 4%, 0% 100%, 100% 100%, 100% 0%);
    clip-path: polygon(0 4%, 0% 100%, 100% 100%, 100% 0%);
  }

  .left-footer-box {
    padding-left: 0;
  }

  .logo-footer {
    width: 250px;
  }

  .logo-img {
    width: 250px;
  }
}

@media (max-width: 500px) {
  .card {
    padding-top: 70px;
  }

  .main-footer {
    height: 1650px;
  }

  .iframe-div {
    width: 280px;
    margin: auto;
  }

  .iframe {
    width: 280px;
    height: 200px;
  }

  .left-footer-box {
    text-align: center;
  }

  .elements-inside {
    width: 280px;
    margin: auto;
  }

  .width-input {
    width: 280px;
  }

  .for-btn {
    width: 280px;
    margin-top: 20px;
  }

  .btn {
    width: 280px;
  }
}
</style>