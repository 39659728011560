<template>
  <NavBarForSmallComponents :panelName="'Despre Companie'" />
  <div class="mobile-view">
    <div
      class="menu-wrap"
      :class="isMenuOpen == true ? 'fixed' : ''"
      :key="keyComp"
    >
      <input
        type="checkbox"
        @click="isMenuOpen = !isMenuOpen"
        class="toggler"
      />
      <div class="hamburger"><div></div></div>
      <div class="menu">
        <div>
          <div class="center-nav-bar-mob">
            <ul>
              <li>
                <router-link to="/" @click="rerenderComp" class="acasa-link"
                  ><a>Acasa</a></router-link
                >
              </li>
              <li>
                <router-link
                  to="/desprecompanie"
                  @click="rerenderComp"
                  class="acasa-link"
                  ><a>Despre Companie</a></router-link
                >
              </li>
              <li>
                <router-link
                  to="/panoudemonitorizare"
                  @click="rerenderComp"
                  class="acasa-link"
                  ><a>Panou de monitorizare</a></router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-on-the-page">
    <Descriere :showTitle="'hide'"/>
  </div>
  <OurServices />
  <OurSocialMedia />
  <FooterSmall />
</template>

<script>
import Descriere from "../components/HomeView/Descriere.vue";
import FooterSmall from "../components/SmallReusableComponents/FooterSmall.vue";
import NavBarForSmallComponents from "../components/SmallReusableComponents/NavBarForSmallComponents.vue";
import OurServices from '../components/AboutUs/OurServices.vue'
import OurSocialMedia from '../components/AboutUs/OurSocialMedia.vue'
export default {
  components: {
    NavBarForSmallComponents,
    Descriere,
    FooterSmall,
    OurServices,
    OurSocialMedia
  },
  data() {
    return {
      keyComp: 0,
      isMenuOpen: false,
    };
  },
  methods: {
    rerenderComp() {
      this.keyComp += 1;
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.despre-noi-dec {
  text-shadow: 3px 3px 3px rgba(150, 150, 150, 0.79);
  text-align: center;
  font-size: 50px;
}
.text-on-the-page {
  max-width: 1000px;
  margin: auto;
}
.mobile-view {
  display: none;
}
.center-nav-bar-mob {
  margin-left: -30px;
}
.acasa-link.router-link-active {
  color: #cd9a30;
}
.acasa-link:hover {
  text-decoration: underline;
}
@media (max-width: 900px) {
  .mobile-view {
    display: block;
  }
}
</style>