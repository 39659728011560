<template>
  <div class="table-box">
    <div v-if="mainTableData.value.length">
      <table class="main-table">
        <tr>
          <th class="text-center">
            Denumirea deseului de metale feroase si neferoase
          </th>
          <th class="text-center">Pret pentru persoane juridice</th>
          <th class="text-center">Pret pentru persoane fizice</th>
        </tr>
        <tr v-for="(item, index) in mainTableData.value" :key="index">
          <td>{{ item.title }}</td>
          <td class="text-center">
            <div class="decor-td">{{ getTwoNumbers(item.pretJuridic, 'juridic') }}</div>
          </td>
          <td class="text-center">
            <div class="decor-td">{{ getTwoNumbers(item.pretJuridic, 'fizic') }}</div>
          </td>
        </tr>
      </table>
      <div class="select-raion">
        <div class="columns">
          <div class="column">
            <h3
              @click="selectRaion('Chisinau')"
              :class="raion == 'Chisinau' ? 'decor-active' : ''"
              class="raion-left decor-raion"
            >
              Chisinau
            </h3>
          </div>
          <div class="column">
            <h3
              @click="selectRaion('Balti')"
              :class="raion == 'Balti' ? 'decor-active' : ''"
              class="raion-right decor-raion"
            >
              Balti
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner-div" v-else>
      <ProgressSpinner class="spinner" />
    </div>
  </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";
import getMainTableData from "@/composables/getMainTableData";
import getMainTableDataBalti from "@/composables/getMainTableDataBalti";
import { ref } from "vue";

export default {
  components: {
    ProgressSpinner,
  },
  setup() {
    const mainTableData = ref([]);
    const raion = ref("Chisinau");

    const getTableData = () => {
      let data;
      if (raion.value == "Chisinau") {
        data = getApiData();
      } else if (raion.value == "Balti") {
        data = getApiDataBalti();
      }
      mainTableData.value = data.metalPrice;
    };

    const getTwoNumbers = (number, tipPersoana) =>{
      if(tipPersoana == 'fizic'){
        return (Number(number)  * 0.88).toFixed(2)
      }else{
        return Number(number).toFixed(2)
      }
    }

    const getApiData = () => {
      const { metalPrice, error, load } = getMainTableData();

      load();

      return { metalPrice, error };
    };

    const getApiDataBalti = () => {
      const { metalPrice, error, load } = getMainTableDataBalti();

      load();

      return { metalPrice, error };
    };

    const selectRaion = (raionVal) => {
      raion.value = raionVal;
      mainTableData.value = [];
      getTableData();
    };

    getTableData();

    return { mainTableData, raion, selectRaion, getTwoNumbers };
  },
};
</script>

<style scoped lang="scss">
.table-box {
  padding: 5px 20px;
  color: white;
  position: absolute;
  width: 586px;

  overflow: hidden;
  th,
  td {
    margin: 0.5em 1em;
  }
  th,
  td:before {
    color: white;
  }
  th {
    font-size: 18px;
    color: #cd9a30;
    padding-bottom: 10px;
  }
  th:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  th:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  td:first-child {
    font-weight: bold;
    font-size: 14px;
  }
}
table {
  border-collapse: collapse;
  border-style: hidden; /* hide standard table (collapsed) border */
}
.select-raion {
  width: 290px;
  margin: auto;
}
.raion-left {
  margin-top: 12px;
  float: left;
}
.raion-right {
  float: right;
  margin-top: 12px;
}
.decor-raion {
  border: 2px solid #cd9a30;
  border-radius: 20px;
  padding: 5px 15px;
}
.decor-raion:hover {
  cursor: pointer;
}
.decor-active {
  background: #cd9a30;
}
.decor-td {
  min-width: 50px;
  background-color: rgba(205, 154, 48, 0.7);
  border-radius: 10px;
  padding: 3px 10px;
  color: white;
  font-weight: bold;
}
td {
  color: white;
}
.spinner-div {
  width: 100px;
  margin: 120px auto;
}
@media (max-width: 660px) {
  .table-box {
    width: 380px;
    padding: 0 10px;
  }
}
@media (max-width: 420px) {
  .table-box {
    width: 290px;
    padding: 0 10px;
  }
  .select-raion {
    width: 200px;
  }
  .spinner-div {
    margin: 320px auto;
  }
}
</style>