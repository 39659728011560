<template>
  <div class="descriere">
    <div class="comp-img">
      <Image
        :src="require(`@/assets/background-images/com.jpg`)"
        imageClass="comp-img"
        alt="Image"
        width="500"
        preview
      />
    </div>
    <div class="right-side-of-descriere">
      <h1 class="text-center color-orange" v-if="showTitle == 'show'">Despre Companie</h1>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Compania Arvi Invest Trading SRL activează în
        domeniul managementului integrat al deșeurilor de metale feroase şi
        neferoase din anul 2021, timp în care a devenit un jucător important de
        pe piața.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Valorile promovate, investițiile realizate,
        experiența acumulată și calitatea serviciilor au clasat compania în
        topul firmelor cu capital privat. Investițiile, infrastructura,
        resursele umane și parteneriatele pe termen lung au contribuit la o
        continua dezvoltare Arvi Invest Trading SRL.
      </p>
      <div class="comp-img-mob-only">
        <Image
          :src="require(`@/assets/background-images/com.jpg`)"
          imageClass="img-mob-only"
          alt="image-company"
          preview
          ><template #indicator> Preview Content </template></Image
        >
      </div>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;Obiectivul companiei noastre este să devină
        lider național în domeniul managementului integrat al deșeurilor de
        metale feroase şi neferoase. Rolul pe care Arvi Invest Trading SRL îl
        deține în piața serviciilor de management al deșeurilor de metale
        feroase şi neferoase se completează cu dorința de a fi un model pentru
        celelalte companii, investițiile în protecția mediului, protecția muncii
        și programele de responsabilitate socială susținând aceste lucruri.
      </p>
    </div>
  </div>
</template>

<script>
import Image from "primevue/image";

export default {
  components: {
    Image,
  },
  props: ['showTitle']
};
</script>

<style scoped>
.descriere {
  width: 1000px;
  margin: auto;
}
.comp-img {
  width: 500px;
  float: left;
  margin: 0 20px 0 15px;
  border-radius: 40px;
}
.comp-img-mob-only {
  display: none;
}
@media (max-width: 1150px) {
  .descriere {
    width: 700px;
  }
  .comp-img {
    display: none;
  }
  .comp-img-mob-only {
    display: block;
    margin: auto;
  }
  .about-comp {
    text-align: center;
  }
}
@media (max-width: 770px) {
  .descriere {
    width: 400px;
  }
  .comp-img-mob-only {
    width: 300px;
  }
  .img-mob-only {
    width: 300px;
  }
}
@media (max-width: 770px) {
  .descriere {
    width: 400px;
  }
  .comp-img-mob-only {
    width: 400px;
  }
  .right-side-of-descriere {
    text-align: center;
  }
}
@media (max-width: 420px) {
  .descriere {
    width: 280px;
  }
  .comp-img-mob-only {
    width: 280px;
  }
}
</style>
<style>
.p-image-preview-container > img {
  border-radius: 40px;
}
.p-image-preview-indicator {
  border-radius: 40px;
}
@media (max-width: 1150px) {
  .p-image-preview-container > img {
    width: 690px;
  }
}
@media (max-width: 770px) {
  .p-image-preview-container > img {
    width: 390px;
  }
}
@media (max-width: 420px) {
  .p-image-preview-container > img {
    width: 280px;
  }
}
</style>