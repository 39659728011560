import errorHandle from '@/common/errorHandle'
import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getMainTableData = () => {

    const metalPrice = ref([])
    const error = ref(null)

    const load = async () => {
        try {
            const res = await projectFirestore.collection('posts').get()
            metalPrice.value = res.docs.map(doc => {
                return { ...doc.data(), id: doc.id }
            })
        }
        catch (err) {
            error.value = err.message
            errorHandle.apiError("firebase get posts", err.message)
        }
    }

    return { metalPrice, error, load }
}

export default getMainTableData