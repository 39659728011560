<template>
  <div
    class="main-date-mind"
    @click.self="closeModifier"
    tabindex="0"
    ref="modal"
    @keydown.esc="closeModifier"
  >
    <div class="modal-stat">
      <div class="page-content" v-if="loading != true">
        <div style="display: inline-block">
          <h3>{{ allSelectedData.title }}</h3>
        </div>
        <div>
          <p>Pret persoane juridice</p>
          <input type="text" v-model="updateData.pretJuridic" />
          <p class="color-red"><span class="star">*</span> Pretul pentru persoane fizice se calculeaza automat conform formulei: <span class="bold">pretPersoaneJuridice * 0.88 = pretPersoaneFizice</span></p>
        </div>
        <div style="width: 90%; margin: auto">
          <button @click="click">Modifica</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from "@/firebase/config";
import errorHandle from "@/common/errorHandle";

export default {
  props: ["allSelectedData"],
  data() {
    return {
      loading: false,
      updateData: {},
    };
  },
  mounted() {
    this.$refs.modal.focus();
  },
  created() {
    this.updateData.pretFizic = this.allSelectedData.pretFizic;
    this.updateData.pretJuridic = this.allSelectedData.pretJuridic;
  },
  methods: {
    closeModifier() {
      this.$emit("closeModifier", this.allSelectedData.oras);
    },
    async click() {
      if (this.allSelectedData.oras === "chisinau") {
        await projectFirestore
          .collection("posts")
          .doc(this.allSelectedData.id)
          .update(this.updateData)
          .then(() => {
            this.closeModifier();
          })
          .catch((err) => {
            errorHandle.apiError("firebase update posts", err);
          });
      } else {
        await projectFirestore
          .collection("posts_balti")
          .doc(this.allSelectedData.id)
          .update(this.updateData)
          .then(() => {
            this.closeModifier();
          })
          .catch((err) => {
            errorHandle.apiError("firebase update posts_balti", err);
          });
      }
    },
  },
};
</script>

<style scoped>
input {
  width: 90%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #cd9a30;
  outline: none;
  color: #cd9a30;
  font-weight: bold;
  margin: 10px auto;
}
button {
  text-decoration: none;
  background: #cd9a30;
  color: white;
  font-weight: bold;
  border: 0;
  border-radius: 20px;
  padding: 10px 20px;
  float: right;
  cursor: pointer;
}
button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.loading {
  padding: 260px 0 20px;
  width: 20px;
  margin: auto;
}
.main-date-mind {
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.3555);
  width: 100%;
  height: 100%;
  z-index: 99;
}
.modal-stat {
  width: 500px;
  min-height: 300px;
  padding: 0 0 20px 20px;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
}
.icon-close {
  cursor: pointer;
}
h3 {
  color: #34495e;
}
@media (max-width: 515px) {
  .modal-stat {
    width: 300px;
  }
}
</style>