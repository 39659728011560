<template>
  <NavBarForSmallComponents :panelName="'Logare admin panel'" />
  <div class="container">
    <form @submit.prevent="handleSubmit" v-if="loading != true">
      <h2>Introduceti datele pentru logare: </h2>
      <input type="email" required placeholder="email" v-model="email" />
      <input type="password" required placeholder="parola" v-model="password" />
      <div class="error">{{ error }}</div>
      <button class="logare">Logare</button>
    </form>
    <div class="loading">
      <ProgressSpinner v-if="loading && error == null" style="width:100px;height:100px" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useLogin from "../../composables/useLogin";
import { useRouter } from "vue-router";
import getUser from "../../composables/getUser";
import NavBarForSmallComponents from "../SmallReusableComponents/NavBarForSmallComponents.vue";
import ProgressSpinner from "primevue/progressspinner";

export default {
  components: {
    NavBarForSmallComponents,
    ProgressSpinner,
  },
  setup() {
    // refs
    const email = ref("");
    const password = ref("");
    const { error, login } = useLogin();
    const router = useRouter();
    const user = getUser();
    const loading = ref(false);

    if (user) {
      router.push({ name: "Admin" });
    }

    const handleSubmit = async () => {
      loading.value = true;
      let res = await login(email.value, password.value);
      loading.value = false;
      if(!error.value){
        router.push({ name: "Admin" });
      }
    };
    return { email, password, handleSubmit, error, loading };
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 90%;
  max-width: 960px;
  margin: 50px auto 40px auto;
  height: 380px;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 0 20px 0;
  border-radius: 40px;
}
.error {
  color: #ff3f80;
  font-size: 14px;
}
button {
  text-decoration: none;
  background: #cd9a30;
  color: white;
  font-weight: bold;
  float: right;
  border: 0;
  border-radius: 20px;
  padding: 10px 20px;
}
form {
  width: 300px;
  margin: 50px auto;
}
label {
  display: block;
  margin: 20px 0 10px;
}
input {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  margin: 10px auto;
}
span {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
button {
  margin: 20px auto;
  cursor: pointer;
}
.loading {
  width: 100px;
  margin: 120px auto auto auto;
}
button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
</style>