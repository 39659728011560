<template>
    <form class="input-filed">
        <div class="field width-input">
            <label for="username2">Nume</label>
            <InputText id="username2" type="username" aria-describedby="username2-help" v-model="form.name"
                class="width-input" />
        </div>
        <div class="field width-input">
            <label for="username2">Email</label>
            <InputText id="username2" type="username" aria-describedby="username2-help" class="width-input"
                v-model="form.email" placeholder="Exemplu: email@email.com" />
            <small id="username2-help" v-if="v$.form.email.$invalid && form.email" class="p-error popUpError">Este necesar
                sa completati campul 'Email' (ex: nume@gmail.com).</small>
        </div>
        <div class="textarea width-input">
            <h4>Comentariu</h4>
            <Textarea v-model="form.message" rows="5" cols="30" class="width-input" />
        </div>
        <div class="for-btn">
            <Button label="Transmite" :disabled="v$.form.$invalid" :loading="loading"
                class="p-button-outlined p-button-warning btn" @click="sendData" />
        </div>
    </form>
</template>
  
<script>
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import { projectFirestore, timestamp } from "@/firebase/config";
import sendEmail from '@/composables/sendEmail.js'
import popUpHandle from "@/common/popUpHandle";
import errorHandle from "@/common/errorHandle";

export default {
    components: {
        InputText,
        Textarea,
        Button
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form: {
                name: "",
                email: "",
                message: ""
            },
            loading: false
        };
    },
    validations() {
        return {
            form: {
                name: { required },
                email: { required, email },
                message: { required }
            }
        }
    },
    methods: {
        async sendEmail() {
            this.loading = true
            try {
                const res = await projectFirestore.collection("contact").add(this.messageData());
                if (res) {
                    await sendEmail.sendEmail(this.emailData())
                    popUpHandle.showTopInfoModal("success", "Mesajul a fost trimis cu succes!", 3000)
                    this.clearData()
                }
            } catch (err) {
                errorHandle.apiError("send Email", err)
            } finally {
                this.loading = false
            }
        },
        async sendData() {
            if (!this.v$.form.$invalid) {
                this.sendEmail()
            }
        },
        emailData() {
            return {
                email: {
                    name: this.form.name,
                    email: this.form.email,
                    message: "Salutare:\n\t" + this.form.message + `\nCu respect,\nNumele: ${this.form.name}\nEmail: ${this.form.email}`
                }
            }
        },
        messageData() {
            return {
                comment: this.form.message,
                email: this.form.email,
                name: this.form.name,
                time: timestamp(),
            };
        },
        clearData() {
            this.form = {
                name: "",
                email: "",
                message: ""
            }
        }
    },
};
</script>
  
<style scoped lang='scss'>
textarea {
    resize: none;
}

.main-footer {
    min-height: 1000px;
    background: url("../../../assets/background-images/footer.jpg");
    -webkit-clip-path: polygon(0 14%, 0% 100%, 100% 100%, 100% 0%);
    clip-path: polygon(0 14%, 0% 100%, 100% 100%, 100% 0%);
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}

.layer {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
}

.card {
    padding-top: 130px;
}

.logo-footer {
    margin-top: 50px;
}

.logo-img {
    width: 150px;
}

.sizes {
    .p-inputtext {
        display: block;
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.field * {
    display: block;
}

.elements-inside {
    width: 400px;
    margin: auto;
}

.width-input {
    width: 400px;
}

.for-btn {
    width: 300px;
    margin-top: 20px;
}

.btn {
    width: 400px;
}

.copyright {
    margin-top: 80px;
}

.write-to-me p {
    text-align: center;
}

.popUpError {
    color: yellow;
}

.input-filed {
    height: 470px;
}

.iframe {
    width: 480px;
    height: 300px;
}

@media (max-width: 1090px) {
    .left-footer-box {
        padding-left: 20px;
    }
}

@media (max-width: 1000px) {
    .main-footer {
        min-height: 1050px;
    }

    .elements-inside {
        margin-top: 50px;
    }

    .logo-footer {
        width: 150px;
        margin: auto;
        margin-top: 50px;
    }

    .card {
        padding-top: 160px;
    }
}

@media (max-width: 985px) {
    .card {
        padding-top: 100px;
    }

    .main-footer {
        height: 1750px;
        -webkit-clip-path: polygon(0 4%, 0% 100%, 100% 100%, 100% 0%);
        clip-path: polygon(0 4%, 0% 100%, 100% 100%, 100% 0%);
    }

    .left-footer-box {
        padding-left: 0;
    }

    .logo-footer {
        width: 250px;
    }

    .logo-img {
        width: 250px;
    }
}

@media (max-width: 500px) {
    .card {
        padding-top: 70px;
    }

    .main-footer {
        height: 1650px;
    }

    .iframe-div {
        width: 280px;
        margin: auto;
    }

    .iframe {
        width: 280px;
        height: 200px;
    }

    .left-footer-box {
        text-align: center;
    }

    .elements-inside {
        width: 280px;
        margin: auto;
    }

    .width-input {
        width: 280px;
    }

    .for-btn {
        width: 280px;
        margin-top: 20px;
    }

    .btn {
        width: 280px;
    }
}
</style>